var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "journal_container full_overlay" },
    [
      _c(
        "transition",
        {
          attrs: {
            name: "custom-fade",
            "enter-active-class": "animated fadeIn",
            "leave-active-class": "animated fadeOut",
            appear: "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "journal-wrapper",
              style: _vm.journalWrapperDimensions,
            },
            [
              _c("JournalButtons"),
              _c("div", { staticClass: "pages-container" }, [
                _c(
                  "div",
                  { staticClass: "pages", attrs: { id: "journal-pages" } },
                  _vm._l(_vm.chapters, function (chapter) {
                    return _c("JournalChapter", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: chapter.name === _vm.activePage,
                          expression: "chapter.name === activePage",
                        },
                      ],
                      key: chapter.name,
                      class: "journal-" + chapter.name,
                      attrs: {
                        "chapter-pages": chapter.pages,
                        "default-title": chapter.title,
                        "header-title": chapter.label,
                        "img-inner-path": chapter.imgInnerPath,
                        "img-full-suffix": chapter.imgFullSuffix,
                        "img-small-suffix": chapter.imgSmallSuffix,
                        "large-img": chapter.largeImg,
                        "small-img": chapter.smallImg,
                      },
                    })
                  }),
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }