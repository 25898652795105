<template>
  <div class="journal_container full_overlay">
    <transition
      name="custom-fade"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      appear
    >
      <div
        class="journal-wrapper"
        :style="journalWrapperDimensions"
      >
        <JournalButtons />

        <div class="pages-container">
          <div
            id="journal-pages"
            class="pages"
          >
            <JournalChapter
              v-for="chapter in chapters"
              v-show="chapter.name === activePage"

              :key="chapter.name"
              :class="'journal-' + chapter.name"
              :chapter-pages="chapter.pages"
              :default-title="chapter.title"
              :header-title="chapter.label"
              :img-inner-path="chapter.imgInnerPath"
              :img-full-suffix="chapter.imgFullSuffix"
              :img-small-suffix="chapter.imgSmallSuffix"
              :large-img="chapter.largeImg"
              :small-img="chapter.smallImg"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import JournalButtons from '@/components/Journal/Buttons'

export default {
  name: 'Journal',
  components: {
    JournalButtons,
    JournalChapter: () => import('@/components/JournalChapter')
  },
  computed: {
    activePage() {
      return this.$store.getters['journal/activePage']
    },

    screenRatio() {
      return this.$store.getters.screenRatio
    },

    journalWrapperDimensions() {
      const ratio = 848 / 1379
      let width
      let height

      if (this.screenRatio < 1.5) {
        width = window.innerWidth * 80 / 100
        height = width / ratio
      }
      else {
        height = window.innerHeight * 80 / 100
        width = height / ratio
      }

      return {
        width: `${width}px`,
        height: `${height}px`
      }
    },

    characters() {
      return [
        {
          name: this.$i18n.t('characters.antoinette.name'),
          fileName: this.$i18n.t('characters.antoinette.fileName'),
          extraInfo: this.$i18n.t('characters.antoinette.extraInfo'),
          description: this.$i18n.t('characters.antoinette.description')
        },
        {
          name: this.$i18n.t('characters.kraven.name'),
          fileName: this.$i18n.t('characters.kraven.fileName'),
          extraInfo: this.$i18n.t('characters.kraven.extraInfo'),
          description: this.$i18n.t('characters.kraven.description')
        },
        {
          name: this.$i18n.t('characters.willem.name'),
          fileName: this.$i18n.t('characters.willem.fileName'),
          extraInfo: this.$i18n.t('characters.willem.extraInfo'),
          description: this.$i18n.t('characters.willem.description')
        },
        {
          name: this.$i18n.t('characters.tyara.name'),
          fileName: this.$i18n.t('characters.tyara.fileName'),
          extraInfo: this.$i18n.t('characters.tyara.extraInfo'),
          description: this.$i18n.t('characters.tyara.description')
        },
        {
          name: this.$i18n.t('characters.barakov.name'),
          fileName: this.$i18n.t('characters.barakov.fileName'),
          extraInfo: this.$i18n.t('characters.barakov.extraInfo'),
          description: this.$i18n.t('characters.barakov.description')
        }
      ]
    },
    artifacts() {
      return [
        {
          name: this.$i18n.t('artifacts.flora_panacea.name'),
          fileName: this.$i18n.t('artifacts.flora_panacea.fileName'),
          description: this.$i18n.t('artifacts.flora_panacea.description')
        },
        {
          name: this.$i18n.t('artifacts.noble_visage.name'),
          fileName: this.$i18n.t('artifacts.noble_visage.fileName'),
          description: this.$i18n.t('artifacts.noble_visage.description')
        },
        {
          name: this.$i18n.t('artifacts.treasure_trove_of_temperus.name'),
          fileName: this.$i18n.t('artifacts.treasure_trove_of_temperus.fileName'),
          description: this.$i18n.t('artifacts.treasure_trove_of_temperus.description')
        },
        {
          name: this.$i18n.t('artifacts.sceptre_of_souls.name'),
          fileName: this.$i18n.t('artifacts.sceptre_of_souls.fileName'),
          description: this.$i18n.t('artifacts.sceptre_of_souls.description')
        },
        {
          name: this.$i18n.t('artifacts.mirror_of_the_oracle.name'),
          fileName: this.$i18n.t('artifacts.mirror_of_the_oracle.fileName'),
          description: this.$i18n.t('artifacts.mirror_of_the_oracle.description')
        },
        {
          name: this.$i18n.t('artifacts.somnian_nectar.name'),
          fileName: this.$i18n.t('artifacts.somnian_nectar.fileName'),
          description: this.$i18n.t('artifacts.somnian_nectar.description')
        },
        {
          name: this.$i18n.t('artifacts.fallen_star.name'),
          fileName: this.$i18n.t('artifacts.fallen_star.fileName'),
          description: this.$i18n.t('artifacts.fallen_star.description')
        },
        {
          name: this.$i18n.t('artifacts.seekers_eye.name'),
          fileName: this.$i18n.t('artifacts.seekers_eye.fileName'),
          description: this.$i18n.t('artifacts.seekers_eye.description')
        }
      ]
    },
    avatars() {
      return this.$store.getters.gameplay.avatarSelection.avatarList
    },
    islands() {
      return [
        {
          name: this.$i18n.t('islands.alchimeia.name'),
          fileName: this.$i18n.t('islands.alchimeia.fileName'),
          description: this.$i18n.t('islands.alchimeia.description')
        },
        {
          name: this.$i18n.t('islands.fabella.name'),
          fileName: this.$i18n.t('islands.fabella.fileName'),
          description: this.$i18n.t('islands.fabella.description')
        },
        {
          name: this.$i18n.t('islands.temperus.name'),
          fileName: this.$i18n.t('islands.temperus.fileName'),
          description: this.$i18n.t('islands.temperus.description')
        },
        {
          name: this.$i18n.t('islands.morbida.name'),
          fileName: this.$i18n.t('islands.morbida.fileName'),
          description: this.$i18n.t('islands.morbida.description')
        },
        {
          name: this.$i18n.t('islands.oracle.name'),
          fileName: this.$i18n.t('islands.oracle.fileName'),
          description: this.$i18n.t('islands.oracle.description')
        },
        {
          name: this.$i18n.t('islands.sylva.name'),
          fileName: this.$i18n.t('islands.sylva.fileName'),
          description: this.$i18n.t('islands.sylva.description')
        },
        {
          name: this.$i18n.t('islands.lumina.name'),
          fileName: this.$i18n.t('islands.lumina.fileName'),
          description: this.$i18n.t('islands.lumina.description')
        },
        {
          name: this.$i18n.t('islands.veritas.name'),
          fileName: this.$i18n.t('islands.veritas.fileName'),
          description: this.$i18n.t('islands.veritas.description')
        }
      ]
    },
    chapters() {
      return [
        { name: 'characters', imgInnerPath: 'journal/', imgFullSuffix: '-full', imgSmallSuffix: '', pages: this.characters, title: 'fileName', label: 'name' },
        { name: 'artifacts', imgInnerPath: 'world_map/artifacts/', imgFullSuffix: '', imgSmallSuffix: '', pages: this.artifacts, title: 'fileName', label: 'name' },
        { name: 'book', imgInnerPath: 'journal/', imgFullSuffix: '', imgSmallSuffix: '', pages: this.avatars, title: 'defaultName', largeImg: 'bigDesktopPath', smallImg: 'smallDesktopPath', label: 'defaultName' },
        { name: 'map', imgInnerPath: 'world_map/islands/raw/', imgFullSuffix: '_island_raw', imgSmallSuffix: '_round', pages: this.islands, title: 'fileName', label: 'name' }
      ]
    }
  },

  created() {
    window.addEventListener('keydown', this.escButton)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.escButton)
  },

  methods: {
    escButton(e) {
      if (e.keyCode === 27) {
        this.$store.dispatch('journal/close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .journal-wrapper {
    background: url('~assets/img/journal/journal-frame-bg.png') no-repeat scroll;
    box-sizing: border-box;
    margin: 8vh auto;
    z-index: 99;
    position: relative;
    background-size: 100% auto;
  }

  .pages-container {
    position: absolute;
    top: 4%;
    right: 3%;
    bottom: 4%;
    left: 3%;
  }
</style>
