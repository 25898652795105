<template>
  <nav>
    <a
      v-for="chapter in chapters"
      :key="chapter"
      class="journal-button"
      :class="buttonClasses(chapter)"
      @click="activePage = chapter"
    />

    <a
      class="journal-button exit-journal"
      @click="closeJournal"
    />
  </nav>
</template>

<script>
export default {
  name: 'JournalButtons',
  computed: {
    activePage: {
      set(val) {
        this.$store.commit('journal/activePage', val)
      },
      get() {
        return this.$store.getters['journal/activePage']
      }
    },

    chapters() {
      return this.$store.getters['journal/chapters']
    }
  },
  methods: {
    buttonClasses(chapter) {
      const cssClasses = [ chapter ]
      if (chapter === this.activePage) cssClasses.push('active')
      return cssClasses
    },

    closeJournal() {
      this.$store.dispatch('journal/close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .journal-button {
    position: absolute;
    top: 10%;
    right: -4%;
    width: 171px;
    height: 111px;
    max-width: 12.4%;
    max-height: 13.1vh;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: scroll;

    &:hover,
    &.active {
      right: -7%;
    }
  }

  .characters {
    background-image: url('~assets/img/journal/buttons/npc.png');
  }

  .artifacts {
    top: 27%;
    background-image: url('~assets/img/journal/buttons/artifacts.png');
  }

  .book {
    top: 44%;
    background-image: url('~assets/img/journal/buttons/book.png');
  }

  .map {
    top: 61%;
    background-image: url('~assets/img/journal/buttons/map.png');
  }

  .exit-journal {
    top: 78%;
    background-image: url('~assets/img/journal/buttons/close.png');
  }
</style>
