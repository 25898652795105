var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _vm._l(_vm.chapters, function (chapter) {
        return _c("a", {
          key: chapter,
          staticClass: "journal-button",
          class: _vm.buttonClasses(chapter),
          on: {
            click: function ($event) {
              _vm.activePage = chapter
            },
          },
        })
      }),
      _c("a", {
        staticClass: "journal-button exit-journal",
        on: { click: _vm.closeJournal },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }